<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">Danh sách coupon group</h1>
    </div>

    <div class="pt-5 pl-5 pr-5">
<!--      <v-btn-->
<!--        rounded-->
<!--        color="primary"-->
<!--        class="mb-5"-->
<!--        x-small-->
<!--        large-->
<!--        @click="openDialogGroup"-->
<!--      >-->
<!--        <v-icon color="white" dark>mdi-plus</v-icon-->
<!--        ><span style="color: white">Thêm Coupon Group</span>-->
<!--      </v-btn>-->
      <div>
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-row>
              <v-col cols="12" class="d-flex align-items-center pb-0">
                <label class="text-body-1 font-weight-medium mb-6"
                  >Trạng thái:</label
                >
                <v-radio-group class="mt-0 pt-0" v-model="status_filter" row>
                  <v-radio label="Active" :value="status.ACTIVE"></v-radio>
                  <v-radio label="Inactive" :value="status.INACTIVE"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="pt-0">
            <v-pagination
              class="mt-5"
              color="#00BCD4"
              style="float: left"
              v-model="data_pagination.current_page"
              :length="data_pagination.total_pages"
              :total-visible="6"
            ></v-pagination>
          </v-col>
          <v-col cols="6">
            <b-form-input
              class="mt-2"
              style="float: right; width: 300px"
              placeholder="Search"
              v-model="input_search"
              @keyup.enter="actionSearchGroup()"
            ></b-form-input>
          </v-col>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Code</th>
                    <th scope="col">Name</th>
                    <th scope="col">Source</th>
                    <th scope="col">Date</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Used</th>
                    <th scope="col">Can combined</th>
                    <th scope="col">Discount Amount</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(group, i) in coupon_groups">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <p
                          style="max-width: 120px; word-break: break-word"
                          class="text-dark-75 font-weight-bolder font-size-lg"
                        >
                          {{ group.code }}
                        </p>
                      </td>
                      <td>
                        <p
                          style="max-width: 180px; word-break: break-word"
                          class="text-dark-75 font-weight-bolder font-size-lg"
                        >
                          {{ group.name }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ transferSource(group.source) }}
                        </p>
                      </td>
                      <td>
                        <p>
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >{{ group.from_date }}</span
                          >
                          <v-icon class="ml-2"
                            >mdi-arrow-right-bold-outline</v-icon
                          >
                          <span
                            class="ml-2 text-dark-75 font-weight-bolder font-size-lg"
                            >{{ group.to_date }}</span
                          >
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ group.quantity | formatNumber }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ group.used | formatNumber }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg light-blue--text text--lighten-1"
                          v-if="group.can_combined === 1"
                        >
                          Có thể kết hợp ct giảm khác
                        </p>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg cyan--text text--lighten-1"
                          v-if="group.can_combined === 0"
                        >
                          Không thể kết hợp ct giảm khác
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg pt-4 blue--text text--lighten-1"
                          v-if="group.discount_type === 1"
                        >
                          {{ group.discount_amount | formatNumber }} VND
                        </p>
                        <span
                          class="label label-lg label-inline font-weight-bold py-4 label-light-warning"
                          v-if="group.discount_type === 2"
                          >{{ group.discount_amount }} %</span
                        >
                      </td>
                      <td>
<!--                        <div style="display: flex">-->
<!--                          <v-tooltip-->
<!--                            left-->
<!--                            v-if="status_filter === status.ACTIVE"-->
<!--                          >-->
<!--                            <template v-slot:activator="{ on, attrs }">-->
<!--                              <button-->
<!--                                class="btn btn-icon btn-light-success btn-sm"-->
<!--                                v-bind="attrs"-->
<!--                                v-on="on"-->
<!--                                @click="detailGroup(i)"-->
<!--                              >-->
<!--                                <span-->
<!--                                  class="svg-icon svg-icon-md svg-icon-primary"-->
<!--                                >-->
<!--                                  <v-icon color=""-->
<!--                                    >mdi-square-edit-outline</v-icon-->
<!--                                  >-->
<!--                                </span>-->
<!--                              </button>-->
<!--                            </template>-->
<!--                            <span>Edit</span>-->
<!--                          </v-tooltip>-->
<!--                          <v-tooltip left>-->
<!--                            <template v-slot:activator="{ on, attrs }">-->
<!--                              <button-->
<!--                                class="btn btn-icon btn-light-primary btn-sm ml-2"-->
<!--                                v-bind="attrs"-->
<!--                                v-on="on"-->
<!--                                @click="showCouponList(group.id)"-->
<!--                              >-->
<!--                                <span-->
<!--                                  class="svg-icon svg-icon-md svg-icon-primary"-->
<!--                                >-->
<!--                                  <v-icon color=""-->
<!--                                    >mdi-clipboard-list-outline</v-icon-->
<!--                                  >-->
<!--                                </span>-->
<!--                              </button>-->
<!--                            </template>-->
<!--                            <span>Danh sách coupon</span>-->
<!--                          </v-tooltip>-->
<!--                          <v-tooltip-->
<!--                            right-->
<!--                            v-if="status_filter === status.ACTIVE"-->
<!--                          >-->
<!--                            <template v-slot:activator="{ on, attrs }">-->
<!--                              <button-->
<!--                                class="btn btn-icon btn-light-warning btn-sm ml-2"-->
<!--                                v-bind="attrs"-->
<!--                                v-on="on"-->
<!--                                @click="btnArchivedGroup(group.id)"-->
<!--                              >-->
<!--                                <span-->
<!--                                  class="svg-icon svg-icon-md svg-icon-primary"-->
<!--                                >-->
<!--                                  <v-icon color=""-->
<!--                                    >mdi-archive-arrow-up-outline</v-icon-->
<!--                                  >-->
<!--                                </span>-->
<!--                              </button>-->
<!--                            </template>-->
<!--                            <span>Archive</span>-->
<!--                          </v-tooltip>-->
<!--                        </div>-->
                      </td>
                    </tr>
                  </template>
                  <template v-if="!coupon_groups.length">
                    <tr>
                      <td style="text-align: center" colspan="12">No data</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
          <v-col cols="6">
            <v-pagination
              class="mt-5"
              color="#00BCD4"
              style="float: left"
              v-model="data_pagination.current_page"
              :length="data_pagination.total_pages"
              :total-visible="6"
            ></v-pagination>
          </v-col>
        </v-row>
      </div>
    </div>

    <GroupForm
      :show_dialog="dialogGroup"
      :type_form="type_form"
      :source_coupon="source_coupon"
      :programme_tags="programme_tags"
      :product_categories="product_categories"
      :coupon_group="coupon_group"
      @setDialog="(e) => (this.dialogGroup = e)"
      @resetData="getAllCouponGroup()"
    />

    <CouponList
      :show_dialog="dialogCouponList"
      :coupons="coupons"
      @setDialog="(e) => (this.dialogCouponList = e)"
    />
  </v-app>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import Swal from "sweetalert2";
export default {
  name: "GroupList",
  components: {
    Loading: () => import("vue-loading-overlay"),
    GroupForm: () => import("@/view/pages/coupon-group/modal/GroupForm"),
    CouponList: () => import("@/view/pages/coupon-group/modal/CouponList"),
  },
  data() {
    return {
      is_call_api: false,
      coupon_groups: [],
      dialogGroup: false,
      data_pagination: {
        current_page: 1,
        total_pages: 1,
      },
      input_search: "",
      status: {
        ACTIVE: 1,
        INACTIVE: 0,
      },
      status_filter: 1,
      source_coupon: [],
      type_form: null,
      product_categories: [],
      programme_tags: [],
      coupon_group: {},
      coupons: [],
      dialogCouponList: false,
    };
  },
  watch: {
    status_filter() {
      this.getAllCouponGroup();
    },
  },
  created() {
    this.status_filter = this.status.ACTIVE;
    this.getAllCouponGroup();
    this.getListSourceCoupon();
    this.getAllProgramme();
    this.getAllProductCategory();
  },
  methods: {
    async getListSourceCoupon() {
      let vm = this;
      await ApiService.get("prep-app/coupon/list-source").then(function (
        response
      ) {
        if (response.status === 200) {
          vm.source_coupon = response.data;
        }
      });
    },
    async getAllCouponGroup(page = 1, query = "") {
      let vm = this;
      vm.is_call_api = true;
      await ApiService.get(
        "prep-app/coupon-group?page=" +
          page +
          "&status=" +
          this.status_filter +
          "&query=" +
          query
      ).then(function (response) {
        if (response.status === 200) {
          vm.coupon_groups = response.data.data;
          vm.data_pagination = response.data.pagination;
          vm.is_call_api = false;
        }
      });
    },
    async getAllProgramme() {
      let vm = this;
      await ApiService.get("prep-app/programme/limit").then(function (
        response
      ) {
        if (response.status === 200) {
          vm.programme_tags = response.data;
        }
      });
    },
    async getAllProductCategory() {
      let vm = this;
      await ApiService.get("prep-app/product-category/limit").then(function (
        response
      ) {
        if (response.status === 200) {
          vm.product_categories = response.data;
        }
      });
    },
    actionSearchGroup() {
      this.getAllCouponGroup(1, this.input_search);
    },
    transferSource(source_value) {
      let data = this.source_coupon.filter((e) => {
        return e.value === source_value;
      });
      if (data.length) {
        return data[0].label;
      } else {
        return "";
      }
    },
    openDialogGroup() {
      this.type_form = "add";
      this.dialogGroup = true;
    },
    detailGroup(index) {
      this.dialogGroup = true;
      this.coupon_group = this.coupon_groups[index];
      this.type_form = "update";
    },
    btnArchivedGroup(group_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete("prep-app/coupon-group/" + group_id)
            .then(function (res) {
              if (res.status === 200) {
                vm.$toasted.success("Xóa thành công !!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.getAllCouponGroup();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    async showCouponList(group_id) {
      let vm = this;
      vm.is_call_api = true;
      await ApiService.get(
        "prep-app/coupon-group/" + group_id + "/list-coupon"
      ).then(function (response) {
        if (response.status === 200) {
          vm.coupons = response.data;
          vm.dialogCouponList = true;
          vm.is_call_api = false;
        }
      });
    },
  },
};
</script>

<style scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.table td {
  vertical-align: middle;
}
.label.label-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
}
.btn.btn-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}
.btn.btn-light-success i {
  color: #3699ff;
}
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #3699ff;
}
</style>
